<template>
<div id="comingSoon">
  <video autoplay loop muted class="videoBackground">
    <source src="../assets/videos/background.mp4" type="video/mp4">
  </video>
  <div class="content d-flex justify-center align-center">
    <div class="mainContanier">
    <div class="logo d-flex justify-center align-center">
      <img src="../assets/icons/logo.svg" class="logoImage">
    </div>
      <div class="mainTitle  d-flex justify-center align-center">
        Coming Soon
      </div>
      <div class="secTitle  d-flex justify-center align-center">
        our website is under construction
      </div>
<!--      <div>-->
<!--        <p style="color: white"> Days {{ timeLeft.hours }} Hours {{ timeLeft.minutes }} Minutes {{ timeLeft.seconds }} Seconds</p>-->
<!--      </div>-->
      <div class=" d-flex justify-center align-center" style="color: white; width: 100%; padding: 150px 0px;">
        <diV class="d-flex justify-center align-center counterContainer">
          <div class="days">
            <div>
             <div  class="d-flex justify-center align-center number">{{ timeLeft.days }}</div>
              <div class="d-flex justify-center align-center numberText">Days</div>
            </div>
          </div>
          <div class="hours">
            <div>
              <div class="d-flex justify-center align-center number">{{ timeLeft.hours}}</div>
              <div class="d-flex justify-center align-center numberText">Hours</div>
            </div>
          </div>
          <div class="minutes">
            <div >
              <div class="d-flex justify-center align-center number">{{timeLeft.minutes}}</div>
              <div class="d-flex justify-center align-center numberText">Minutes</div>
            </div>
          </div>
        </diV>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'comingSoon',
  data () {
    return {
      targetDate: '2024-8-13',
      timeLeft: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  mounted () {
    this.calculateTimeLeft()
    this.timer = setInterval(this.calculateTimeLeft, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    calculateTimeLeft () {
      const now = new Date()
      const target = new Date(this.targetDate)
      const diff = target - now

      if (diff <= 0) {
        clearInterval(this.timer)
        this.timeLeft = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      } else {
        this.timeLeft = {
          days: Math.floor(diff / (1000 * 60 * 60 * 24)),
          hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((diff / 1000 / 60) % 60),
          seconds: Math.floor((diff / 1000) % 60)
        }
      }
    }
  }
}
</script>
<style lang="scss">
  html{
    overflow: hidden !important;
  }
</style>
<style lang="scss" scoped>
#comingSoon {
  height: 100vh;
  position: relative;
  font-family: exrReg;
  /*overflow: hidden;*/
}
 .videoBackground {
   position: absolute;
   /*top: 50%;*/
   /*left: 50%;*/
   width: 100%;
   height: 100%;
   object-fit: cover;
   /*transform: translate(-50%, -50%);*/
   z-index: 1;
 }
  .content {
    position: relative;
    z-index: 2;
  }
  .mainContanier{
    height: 100vh;
    width: 100%;
    overflow: auto;
  }
  .logo {
    padding: 55px 0 35px 0;
  }
  .mainTitle{
    color: #0062FF;
    font-size: 99px;
    padding: 20px;
  }
  .secTitle {
    color: white;
    font-size: 36px;
    font-family: exrThin;
  }
  .counterContainer {
    width: 65vw;
    background: rgb(0 0 0 /50%);
    backdrop-filter: blur(7px);
    height: 250px;
    min-width: 300px;
  }
  .hours, .days, .minutes {
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
  }
  .number {
    color: #EFFF00;
    font-size: 80px;
  }
  .hours {
    border-right: 1.5px white solid;
    border-left: 1.5px white solid;
  }
.numberText {
  font-size: 25px;
}
@media screen and (max-width: 1500px) {
  .mainTitle {
    font-size: 89px;
  }
  .secTitle{
    font-size: 30px;
  }
  .number {
    font-size: 30px;
  }
  .logoImage {
    width: 400px;
  }
  .number {
    font-size: 70px;
  }
  .numberText{
    font-size: 20px;
  }
}
@media screen and (max-width: 950px) {
  .mainTitle {
    font-size: 79px;
  }
  .secTitle{
    font-size: 20px;
  }
  .number {
    font-size: 30px;
  }
  .logoImage {
    width: 300px;
  }
   .number {
    font-size: 60px;
  }
  .numberText{
    font-size: 20px;
  }
}
@media screen and (max-width: 860px) {
  .mainTitle {
    font-size: 60px;
  }
  .secTitle{
    font-size: 17px;
  }
  .number {
    font-size: 35px;
  }
  .logoImage {
    width: 250px;
  }
  .numberText{
    font-size: 20px;
  }
}
@media screen and (max-width: 660px) {
  .mainTitle {
    font-size: 50px;
  }
  .secTitle{
    font-size: 15px;
  }
  .number {
    font-size: 30px;
  }
  .logoImage {
    width: 250px;
  }
  .numberText{
    font-size: 15px;
  }
  .counterContainer{
    height: 200px;
  }
}
@media screen and (max-width: 560px) {
  .mainTitle {
    font-size: 40px;
  }
  .secTitle{
    font-size: 12px;
  }
  .number {
    font-size: 25px;
  }
  .logoImage {
    width: 250px;
  }
  .numberText{
    font-size: 12px;
  }
  .counterContainer{
    height: 200px;
  }
}
@media screen and (max-width: 450px) {
  .mainTitle {
    font-size: 30px;
  }
  .secTitle{
    font-size: 10px;
  }
  .number {
    font-size: 20px;
  }
  .logoImage {
    width: 250px;
  }
  .numberText{
    font-size: 10px;
  }
  .counterContainer{
    height: 200px;
  }
}
@media screen and (max-width: 350px) {
  .mainTitle {
    font-size: 25px;
  }
  .secTitle{
    font-size: 7px;
  }
  .number {
    font-size: 15px;
  }
  .logoImage {
    width: 250px;
  }
  .numberText{
    font-size: 7px;
  }
  .counterContainer{
    height: 200px;
  }
}
</style>
