<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
  @font-face {
    font-family: exrBold;
    src: url("./assets/fonts/bold.otf");
  }
  @font-face {
    font-family: exrReg;
    src: url("./assets/fonts/reg.otf");
  }
  @font-face {
    font-family: exrThin;
    src: url("./assets/fonts/thin.otf");
  }
</style>
